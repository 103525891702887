<template>
  <div class="tool-layout">
    <div class="tool-header">
      <img alt="logo" src="@/assets/images/fuyiTool/header-logo.png" />
      <div class="tool-header-title">
        <slot name="title">这里是标题</slot>
      </div>
    </div>
    <div class="tool-content">
      <slot></slot>
    </div>

  </div>
</template>

<script>

</script>

<style lang="less" scoped>
.tool-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tool-header {
  height: 60px;
  background-color: white;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.tool-content {
  flex: 1;
  overflow: hidden;
}

.tool-header-title {
  font-size: 22px;
  font-weight: 700;
  color: var(--el-text-color-primary);
  margin-left: 15px;
}
</style>