<template>
  <span class="loading-text">
    <span class="dot1"></span>
    <span class="dot2"></span>
    <span class="dot3"></span>
  </span>
</template>

<style lang="less" scoped>
.loading-text {
  > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: gray;
    border-radius: 50%;

    & + span {
      margin-left: 5px;
    }
  }

  .dot1 {
    animation: dot 1s infinite;
  }

  .dot2 {
    animation: dot 1s infinite 0.2s;
  }

  .dot3 {
    animation: dot 1s infinite 0.4s;
  }

  .dot4 {
    animation: dot 1s infinite 0.6s;
  }

  @keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>