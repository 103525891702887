<template>
  <layout>
    <template #title>
      法治知识问答
    </template>
    <div class="chat-area">
      <div class="conversation-area">
        <el-scrollbar ref="conversation-scroll">
          <div class="conversation-scroll-area" ref="conversation-scroll-inner">
            <div class="message-item" v-for="(item, idx) in messages" :class="{reverse: item.isMe}">
              <div class="message-avatar">
                <img v-if="item.isMe" alt="avatar" src="@/assets/images/fuyiTool/user-avatar.png" />
                <img v-else alt="avatar" src="@/assets/images/fuyiTool/ai2-avatar.png" />
              </div>
              <div class="message-content">
                <loading v-if="answering && idx === messages.length -1 && !item.content"></loading>
                <div v-html="item.content"></div>
                <div class="message-ref" v-if="item.ref && item.ref.length > 0">
                  <div class="message-ref-item" v-for="(r, ridx) in item.ref" @click="open(r)">
                    {{ ridx + 1 }}、{{ r.source }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="input-area">
        <el-input :rows="8"
                  v-model="inputContent"
                  show-word-limit
                  type="textarea"
                  maxlength="1000"
                  placeholder="通过 [Shift + 回车] 换行"
                  @keydown="handleKeyCode($event)">
        </el-input>
        <el-icon class="input-send" :class="{answering: answering || !inputContent}" @click="send">
          <Promotion />
        </el-icon>
        <!--        <div class="input-tip">-->
        <!--          <span>Shift + 回车：换行</span>-->
        <!--          <span>回车：发送</span>-->
        <!--        </div>-->
      </div>
    </div>
  </layout>
</template>

<script>
import layout from '../common/layout.vue'
import loading from '../common/loading.vue'
import { Promotion } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus/lib/components'

export default {
  components: {
    Promotion,
    layout,
    loading
  },
  data() {
    return {
      ws: null,
      answering: false,
      inputContent: undefined,
      messages: [
        {
          content: '',
          isMe: false
        }
      ]
    }
  },
  created() {
    this.connectWebSocket()
  },
  mounted() {
    this.scrollBottom()
    // 初始化第一条消息
    this.answering = true
    let index = 0
    let firstMessage = '您好！我是北大软件研发的法律依据推荐助手。以下是我能够为您提供的具体功能：\r\n' +
      '1.法律咨询回答：我可以回答您提出的法律咨询问题，我会根据我的法律知识库，尽力为您提供准确的答案和解释。\r\n' +
      '2.法律推荐：如果您提供一段摘要，我会通过语义理解来推荐与您提供的信息相关的法律条文或法规。\r\n' +
      '3.法律全文阅读：如果您需要进一步的法律信息或参考资料，可以点击我提供法律条文，即可跳转法律原文。'
    let firstMessages = firstMessage.split('')
    let content = ''
    let it = setInterval(() => {
      if (index < firstMessages.length) {
        content += firstMessages[index++]
        this.messages.at(-1).content = `<div>${content}</div>`
      } else {
        clearInterval(it)
        this.answering = false
      }
    }, 10)
  },
  computed: {
    history() {
      let his = []
      this.messages.forEach(m => {
        if (m.isMe) {
          his.push({ send: m.content, rev: '' })
        } else if (his.length === 0) {
          // his.push({ send: '', rev: '' })
          // his.at(-1).rev = m.content
        } else {
          his.at(-1).rev = m.content
        }
      })
      return his
    }
  },
  methods: {
    handleKeyCode(event) {
      if (event.keyCode === 13) {
        if (!event.shiftKey) {
          event.preventDefault()
          this.send()
        } else {
          searchValue.value += '\r\n'
        }
      }
    },
    connectWebSocket() {
      // 创建 WebSocket 连接
      this.ws = new WebSocket('ws://192.168.4.195:8086/echo')

      // 连接打开事件
      this.ws.onopen = () => {
        console.log('WebSocket connection opened')
      }

      // 收到消息事件
      this.ws.onmessage = (event) => {
        const message = JSON.parse(event.data)
        if (message.type === 'fin') {
          this.answering = false
          this.scrollBottom()
        } else {
          this.messages.at(-1).ref = message.ref
          this.messages.at(-1).content = message.answer
          this.scrollBottom()

          // let spans = message.span || []
          // let startMap = {}
          // let endMap = {}
          // spans.forEach(item => {
          //   startMap[item.start] = item
          //   endMap[item.end] = item
          // })
          //
          // let refs = message.ref || []
          // let refMap = {}
          // let rindex = 0
          // refs.forEach(item => {
          //   refMap[rindex++] = item
          // })
          //
          // let resMessage = message.answer
          // let resMessages = resMessage.split('')
          // let index = 0
          // this.messages.at(-1).content = resMessages.flatMap(m => {
          //   let f = []
          //   let s = startMap[index]
          //   let e = endMap[index]
          //   if (s) {
          //     f.push(`<a href="${refMap[s.source].link}">`)
          //   }
          //   f.push(m)
          //   if (e) {
          //     f.push(`<a/>`)
          //   }
          //   index++
          //   return f
          // }).join('')
          // this.scrollBottom()
        }
      }

      // 连接关闭事件
      this.ws.onclose = () => {
        console.log('WebSocket connection closed')
        // 重新连接
        setTimeout(() => {
          this.connectWebSocket()
        }, 1000)
      }

      // 连接错误事件
      this.ws.onerror = (error) => {
        console.error('WebSocket error', error)
      }
    },
    scrollBottom() {
      this.$nextTick(() => {
        let scrollElem = this.$refs['conversation-scroll']
        scrollElem.scrollTo({ top: this.$refs['conversation-scroll-inner'].clientHeight, behavior: 'smooth' })
      })
    },
    open(r) {
      // window.open(l)\
      let routeUrl = this.$router.resolve({
        path: '/DetailPage',
        query: { id: r.law_version_id }
      })
      window.open(routeUrl.href, '_blank')
    },
    send() {
      if (this.answering || !this.inputContent) {
        return
      }

      if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
        ElMessage({
          message: '会话连接中，请稍后',
          type: 'warning'
        })
        return
      }

      // 创建消息
      let sendMessage = {
        type: 'q',
        query: this.inputContent,
        history: this.history
      }

      // 回显消息
      this.answering = true
      this.messages.push({ isMe: true, content: this.inputContent })
      this.inputContent = undefined
      this.messages.push({ isMe: false, content: '' })
      this.scrollBottom()

      // 发送消息
      this.ws.send(JSON.stringify(sendMessage))


      // if (this.answering || !this.inputContent) {
      //   return
      // }
      //
      // this.answering = true
      // this.messages.push({ isMe: true, content: this.inputContent })
      // this.inputContent = undefined
      // this.messages.push({ isMe: false, content: '' })
      // this.scrollBottom()
      //
      // let it = null
      // setTimeout(() => {
      //   let i = 0
      //   it = setInterval(() => {
      //     this.messages.at(-1).content += 'ceccecececeeececececceceececeeec' + i++
      //     this.scrollBottom()
      //   }, 200)
      // }, 2000)
      //
      // setTimeout(() => {
      //   clearInterval(it)
      //   this.answering = false
      // }, 5000)
    }
  },
  beforeDestroy() {
    if (this.ws) {
      this.ws.close()
    }
  }
}

</script>

<style lang="less" scoped>
.chat-area {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 1200px;
  padding: 20px;
}

.conversation-area {
  overflow: auto;
  flex: 1;

  .conversation-scroll-area {
    padding: 20px;
  }
}

.message-item {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  min-height: 50px;

  .message-avatar {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 50px;
    padding: 4px;

    img {
      width: 100%;
    }
  }

  .message-content {
    flex: 1;
    max-width: 100%;
    line-height: 24px;
    padding: 15px;
    margin-right: 0;
    margin-left: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 12px rgba(0, 0, 0, .12);
    word-break: break-all;
    white-space: pre-wrap;

    :deep(.dot_underline) {
      text-decoration: underline dotted;
      color: #6177ff;
      cursor: pointer;
    }

    :deep(sup) {
      margin-right: 4px;
      margin-left: 2px;
      color: #6177ff;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .message-ref {
    margin-top: 10px;
    margin-bottom: -10px;
    margin-right: -10px;
    padding-top: 10px;
    border-top: 1px solid lightgrey;
    display: flex;
    flex-wrap: wrap;

    .message-ref-item {
      padding: 6px;
      background-color: #E8F3FF;
      border: 1px dashed #3b3bff;
      margin-right: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: #d6ebff;
        border: 1px solid #3b3bff;
      }
    }
  }

  &.reverse {
    flex-direction: row-reverse;

    .message-content {
      flex: unset;
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

.input-area {
  position: relative;
  margin: 15px;

  .input-send {
    cursor: pointer;
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 30px;
    transition: all 0.2s ease;
    padding: 3px;
    border-radius: 20px;

    svg {
      margin-top: 1px;
      margin-right: 1px;
    }

    &:not(.answering):hover {
      background-color: #eaeaea;
    }

    &.answering:hover {
      background-color: #f6f6f6;
    }

    &.answering {
      color: lightgrey;
    }
  }

  :deep(.el-input__count) {
    margin-right: 38px;
  }

  :deep(.el-textarea__inner) {
    border-radius: 10px;
    resize: none;
  }

  .input-tip {
    display: flex;
    justify-content: space-between;
    padding: 4px;
  }

}

</style>